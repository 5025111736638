<template>
    <v-dialog v-model="dialog"
                persistent
                max-width="600px">  
        <v-card>
            <v-card-title style="text-align:center; display:block;">
                <img src="../assets/CryptoTaxSpace_Logo_Black.png" width="140" alt="logo" />
              
            </v-card-title>
            <v-card-text style="color:black;">
                <span class="text-h6" style="color:blue;">Terms & Conditions</span>
                <p>
                    This website disclaimer is written for all users of CryptoTaxSpace.com.
                </p>
                <p>
                    The parties referred to in this Disclaimer are as follows:
                </p>
                <p class="ml-2">
                    A.	CryptoTaxSpace, us, we: The Company, as the creator, operator, and publisher of CryptoTaxSpace, makes CryptoTaxSpace, and services on it, available to users. The Company, us, we, our, ours and other first-person pronouns will refer to the Company, as well as all employees and affiliates of the Company.
                </p>
                <p class="ml-2">
                    B.	You, the User, the Client: You, as the user of CryptoTaxSpace, will be referred to throughout this Disclaimer with second-person pronouns such as you, your, yours, or as user or client.
                </p>
                <p class="ml-2">
                    C.	Parties: Collectively, the parties to this Disclaimer (the Company and You) will be referred to as Parties.
                </p>
                <p class="ml-2">
                    D.	Services: Any combination of for sale services in CryptoTaxSpace.
                </p>
                <p>
                    By continuing to use cryptotaxspace.com, you assent to each term and section contained in this Disclaimer.
                </p>
                <p>
                    Through your use of CryptoTaxSpace, you acknowledge and agree that information provided by the Company is simply that, information and, and should not be considered a substitute for legal or tax advice under the guidance of a licensed professional. Further, the information provided herein should not be taken as financial planning or for tax accountability. No fiduciary relationship has been created between you and the CryptoTaxSpace.
                </p>
                <p>
                    You hereby understand and acknowledge that through your use of CryptoTaxSpace, you are not being represented by an advisor/accountant or attorney.
                </p>
                <p>
                    You agree that your use of CryptoTaxSpace and Services is at your sole discretion/risk and that any services provided by the Company are on an "as is" basis. The Company hereby expressly disclaims any and all express or implied warranties of any kind, including, but not limited to the implied warranty of fitness for a particular purpose and the implied warranty of services. The Company makes no warranties that CryptoTaxSpace or the Services will meet your needs or that CryptoTaxSpace or Services will be uninterrupted with no errors. The Company also makes no warranties as to the reliability or accuracy, completeness, or quality of any information on CryptoTaxSpace or obtained through the Services. You agree that the Company is not liable for any errors, omissions, loss or damage which may be caused by your use of CryptoTaxSpace or Services, to the fullest extent permitted by law. Any damage that may occur to You, through your computer system, or as a result of loss of your data from your use of CryptoTaxSpace or Services is your sole responsibility.
                </p>
                <p>
                    Documents, information, or other Services received on or through CryptoTaxSpace may not be appropriate for your particular situation, the assessment of which is your sole and exclusive responsibility.
                </p>
                <p>
                    CryptoTaxSpace makes no assurances to any particular outcome based on your use of CryptoTaxSpace or any of the Services, including business, personal, or financial outcomes.
                </p>
                <p>
                    The maximum liability of the Company arising from or relating to your use of CryptoTaxSpace or Services is limited to the greater of eight ($8) dollars (USD) or the amount you paid to the Company in the last tax season. This applies to any and all claims by you, including, but not limited to, lost profits or revenues, consequential or punitive damages, negligence, strict liability, fraud, or torts of any kind.
                </p>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="HideModal()"> Disagree </v-btn>
                <v-btn color="green darken-1" text @click="AcceptTerms()"> Accept Terms and Conditions</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    export default {
        name: 'LegalDisclaimerForm',

        /* COMPONENT DATA  */
        data() {
            return {
                dialog: false,
            };
        },

        /* COMPONENT METHODS  */
        methods: {

            /* PUBLIC METHODS  */
            ShowModal() {
                this.dialog = true;
            },

            HideModal() {
                this.dialog = false;
                //Scroll back to top
                document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;   
            },

            AcceptTerms() {
                this.dialog = false;
                //Scroll back to top
                document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0;  
                this.$emit("acceptTerms", true);
            }
        },
    };
</script>