<template>
    <form class="cts-form-body" @submit.prevent="changePassword" >
        <div class="cts-wrapper" :style="secondaryBackground">
            <img src="../assets/CryptoTaxSpace_Logo.png" width="200" alt="logo" class="mb-4" />
            <div v-bind:class="accountDisplayClass">
                <p class="title">Change Password</p>
                <input type="password" placeholder="Password" v-model="pass" />
                <i class="fa"> <font-awesome-icon :icon="['fa', 'key']" /></i>
                <input type="password" placeholder="Confirm Password" v-model="confirmPass" />
                <i class="fa"> <font-awesome-icon :icon="['fa', 'key']" /></i>
                <button :disabled="disableChangePasswordButton" @click="changePassword()">
                    <i class="spinner"></i>
                    <div v-if="passwordChanged">
                        <i class="fa"> <font-awesome-icon :icon="['fa', 'key']" size="3x" class="mb-4" /></i>
                    </div>
                    <span style="font-size:medium;" v-if="!error">{{buttonText}}</span>
                    <span v-if="error" style="font-size:small;">{{error}}</span>
                    <br />
                    <div v-if="passwordChanged">
                        <div style="background-color:var(--var-theme-color); color:white;" @click="linkTo('login')" class="btn btn-sm mt-4">
                            <i class="fa pr-2"> <font-awesome-icon :icon="['fa', 'user']" /></i>Sign In
                        </div>
                    </div>
                </button>

            </div>
            <footer class="cts-footer"><a style="font-size:large;" target="blank" href="https://taxes.cryptotaxspace.com">CryptoTaxSpace © {{currentYear}}</a></footer>
        </div>
    </form>
</template>

<script>

    import { userService } from '../services/user-service';
    import { serviceHelper } from '../shared/service-helper';

    export default {
        name: 'ChangePassword',

        /* COMPONENT DATA  */
        data() {
            return {
                pass: '',
                confirmPass: '',
                currentYear: '',
                passwordChanged: false,
                error: null,
                isLoading: false,
                secondaryBackground: {
                    backgroundImage: `url(${require('@/assets/dark-space-4.jpg')})`,
                    backgroundRepeat: 'repeat'
                },
            };
        },

        /* LIFE CYCLE METHODS  */
        async mounted() {
            this.currentYear = new Date().getFullYear();
        },

        async created() {

            if (this.key === null || this.key === undefined) {
                //No key present, go back to home page
                this.$router.push({ name: 'home' });
            }
        },

        /* WATCHERS */
        watch: {

            pass: function (val, oldVal) {
                this.error = null;
            },

            confirmPass: function (val, oldVal) {
                this.error = null;
            },

        },

        /* COMPONENT METHODS  */
        methods: {

            async linkTo(link) {
                this.$router.push({ name: link }).catch(err => { });
            },

            async changePassword() {

                //Do nothing if already loading
                if (this.isLoading) return;

                //Reject invalid input
                if (!this.validate()) return;

                this.isLoading = true;     
                this.error = null;
    
                await serviceHelper.simulateAwait(true, 2500);

                var changeForgottenPasswordArgs = {
                    ForgotPasswordKey: `${this.key}`,
                    Password: `${this.pass}`
                };

                var response = await userService.changeForgottenPassword(changeForgottenPasswordArgs);
                this.isLoading = false;

                this.passwordChanged = response.success;

                if (!this.passwordChanged) {
                    this.error = response.error.replace(/['"]+/g, '');
                }
            },

            validate() {

                if (this.pass !== this.confirmPass) {
                    this.error = "Passwords do not match";
                    return false;
                }

                if (this.pass.trim() === "") {
                    this.error = "Password cannot be empty";
                    return false;
                }

                if (!this.validatePassword(this.pass)) {
                    this.error = "Password length must be between 6 - 64 characters";
                    return false;
                }

                return true;            
            },

            validatePassword(password) {
                return /^.{6,64}$/.test(password);
            }

        },

        /* COMPUTED PROPERTIES */
        computed: {

            key() { return this.$route.query.key; },

            accountDisplayClass() {
                if (this.passwordChanged)
                    return "cts-form loading ok";
                else if (this.isLoading)
                    return "cts-form loading"
                else if (this.error !== null)
                    return "cts-form cts-error";
                else return "cts-form";
            },

            buttonText() {
                if (this.passwordChanged)
                    return "Password Changed Successfully";
                else if (this.isLoading)
                    return "Updating Password...";
                else return "Change Password";
            },

            disableChangePasswordButton() {
                return this.isLoading || this.error !== null || this.passwordChanged
            }
        },
    };
</script>