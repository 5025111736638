<template>
    <div class="center">
        <div v-if="!isLoaded">
            <h5 style="color:var(--var-theme-color)">Activating Account</h5>
            <br />
            <font-awesome-icon  class="fa-10x" :icon="['fa', 'cog']" spin />
            <br />
        </div>   
        <span v-if="isLoaded" style="font-size:large">{{pageText}}</span>
    </div>
</template>

<script>

    import { userService } from '../services/user-service';
    import { serviceHelper } from '../shared/service-helper';

    export default {
        name: 'Activate',

        /* COMPONENT DATA  */
        data() {
            return {
                pageText : '',
                isLoaded : false
            };
        },

        /* LIFE CYCLE METHODS  */
        async created() {

            if (this.key === null || this.key === undefined) {
                //No key present, go back to home page
                this.$router.push({ name: 'home' });
            }
            else {
        
                var response = await userService.activate({ activationKey: `${this.key}` });
                await serviceHelper.simulateAwait(true, 1500);
                this.isLoaded = true;
                if (response.success) {
                    this.pageText = "Your account is now activated, please login.";
                    await serviceHelper.simulateAwait(true, 1500);
                    this.$router.push({ name: 'login' });
                }
                else {
                    this.pageText = response.error.replace(/['"]+/g, '');;
                    await serviceHelper.simulateAwait(true, 1500);
                    this.$router.push({ name: 'home' });
                }
            }
        },

        /* COMPUTED PROPERTIES */
        computed: {
            key() { return this.$route.query.key; }
        },
    };
</script>

<style scoped>

    .center {
        background-color: transparent;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%); /* for IE 9 */
        -webkit-transform: translate(-50%, -50%); /* for Safari */
    }
</style>