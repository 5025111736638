<template>
    <form class="cts-form-body" @submit.prevent="resetPassword" >
        <div class="cts-wrapper" :style="secondaryBackground">
            <img src="../assets/CryptoTaxSpace_Logo.png" width="200" alt="logo" class="mb-4" />
            <div v-bind:class="accountDisplayClass">
                <p class="title">Reset Password</p>
                <span style="font-size:small;">Enter the email associated with your account</span>
                <input type="email" placeholder="Email" v-model="email" autofocus />
                <i class="fa"> <font-awesome-icon :icon="['fa', 'envelope']" /></i>
                <div style="text-align:center;">
                    <a href="#" @click="linkTo('login')">Login with existing account</a> <span style="color:black;"> | </span>
                    <a href="#" @click="linkTo('newaccount')">Create New Account</a>
                </div>
                <button :disabled="disableResetButton" @click="resetPassword()">
                    <i class="spinner"></i>
                    <div v-if="accountReset">
                        <i class="fa"> <font-awesome-icon :icon="['fa', 'key']" size="3x" class="mb-4" /></i>
                    </div>
                    <span style="font-size:medium;" v-if="!error">{{buttonText}}</span>
                    <span v-if="error" style="font-size:small;">{{error}}</span>

                    <div v-if="accountReset">
                        <div class="mt-4" style="font-size:medium">Please check your inbox</div>
                        <br />
                        <div style="background-color:blue; color:white;" @click="linkTo('home')" class="btn btn-sm mr-5">
                            <i class="fa pr-2"> <font-awesome-icon :icon="['fa', 'home']" /></i>Home
                        </div>
                        <div style="background-color:var(--var-theme-color); color:white;" @click="linkTo('login')" class="btn btn-sm">
                            <i class="fa pr-2"> <font-awesome-icon :icon="['fa', 'user']" /></i>Sign In
                        </div>
                    </div>
                </button>

            </div>
            <footer class="cts-footer"><a style="font-size:large;" target="blank" href="https://taxes.cryptotaxspace.com">CryptoTaxSpace © {{currentYear}}</a></footer>
        </div>
    </form>
</template>

<script>

    import { userService } from '../services/user-service';
    import { serviceHelper } from '../shared/service-helper';

    export default {
        name: 'ResetPassword',

        /* COMPONENT DATA  */
        data() {
            return {
                email: '',
                currentYear: '',
                accountReset: false,
                error: null,
                isLoading: false,
                secondaryBackground: {
                    backgroundImage: `url(${require('@/assets/dark-space-4.jpg')})`,
                    backgroundRepeat: 'repeat'
                }
            };
        },

        /* LIFE CYCLE METHODS  */
        async mounted() {
            this.currentYear = new Date().getFullYear();
        },

        /* WATCHERS */
        watch: {

            email: function (val, oldVal) {
                this.error = null;
            }

        },

        /* COMPONENT METHODS  */
        methods: {

            async linkTo(link) {
                this.$router.push({ name: link }).catch(err => { });
            },

            async resetPassword() {

                //Do nothing if already loading
                if (this.isLoading) return;

                //Reject invalid input
                if (!this.validate()) return;

                this.isLoading = true;     
                this.error = null;
    
                await serviceHelper.simulateAwait(true, 2500);

                var resetPasswordArgs = {
                    email: `${this.email}`,
                };

                var response = await userService.resetPassword(resetPasswordArgs);
                this.isLoading = false;

                this.accountReset = response.success;

                if (!this.accountReset) {
                    this.error = response.error.replace(/['"]+/g, '');
                }
            },

            validate() {

                //Trim email fields of white space
                this.email = this.email.trim();

                //Validation
                if (!this.validateEmail(this.email)) {
                    this.error = "Invalid email address format";
                    return false;
                }

                return true;            
            },

            validateEmail(email) {
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
            }

        },

        /* COMPUTED PROPERTIES */
        computed: {

            accountDisplayClass() {
                if (this.accountReset)
                    return "cts-form loading ok";
                else if (this.isLoading)
                    return "cts-form loading"
                else if (this.error !== null)
                    return "cts-form cts-error";
                else return "cts-form";
            },

            buttonText() {
                if (this.accountReset)
                    return "Password Reset Email Sent";
                else if (this.isLoading)
                    return "Sending Password Reset Notification...";
                else return "Reset Password";
            },

            disableResetButton() {
                return this.isLoading || this.error !== null || this.accountReset
            }
        },
    };
</script>
