<template>
    <form class="cts-form-body" @submit.prevent="login">
        <div class="cts-wrapper" :style="secondaryBackground">
            <img src="../assets/CryptoTaxSpace_Logo.png" width="200" alt="logo" class="mb-4" />
            <div v-bind:class="loginDisplayClass">
                <p class="title">Member Login</p>
                <input type="text" placeholder="Username" v-model="user" autofocus />
                <i class="fa"> <font-awesome-icon :icon="['fa', 'user']" /></i>
                <input type="password" placeholder="Password" v-model="pass" />
                <i class="fa"> <font-awesome-icon :icon="['fa', 'key']" /></i>
                <div style="text-align:center;">
                    <a href="#" @click="linkTo('newaccount')">Create New Account</a> <span style="color:black;"> | </span>
                    <a href="#" @click="linkTo('resetpassword')">Forgot your password?</a>
                </div>

                <button :disabled="disableLoginButton" @click="login()">
                    <i class="spinner"></i>
                    <span v-if="!error">{{loginText}}</span>
                    <span v-if="error" style="font-size:small;">{{error}}</span>
                </button>
            </div>
            <span v-if="SessionExpired" style="color:white">Session expired, please sign in again.</span>
            <footer class="cts-footer"><a style="font-size:large;" target="blank" href="https://taxes.cryptotaxspace.com">CryptoTaxSpace © {{currentYear}}</a></footer>
        </div>
    </form>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import { userService } from '../services/user-service';
    import { serviceHelper } from '../shared/service-helper';

    export default {
        name: 'Login',

        /* COMPONENT DATA  */
        data() {
            return {
                user: '',
                pass: '',
                error: null,
                isLoading: false,
                currentYear: '',
                secondaryBackground: {
                    backgroundImage: `url(${require('@/assets/dark-space-4.jpg')})`,
                    backgroundRepeat: 'repeat'
                }
            };
        },

        /* PROPS  */
        props: {
            SessionExpired: {
                type: Boolean,
                default: () => false,
            }
        },

        /* WATCHERS  */
        watch: {

            user: function (val, oldVal) {
                this.error = null;
            },

            pass: function (val, oldVal) {
                this.error = null;
            }
        },

        /* LIFE CYCLE METHODS  */
        async mounted() {
            this.currentYear = new Date().getFullYear();
        },

        /* COMPONENT METHODS  */
        methods: {

            ...mapActions(['loginUserAction']),

            async linkTo(link) {
                this.$router.push({ name: link }).catch(err => { });
            },

            async login() {
   
                //Do nothing if already loading
                if (this.isLoading) return;

                //Reject invalid input
                if (!this.validate()) return;

                this.isLoading = true;     
                this.error = null;
    
                await serviceHelper.simulateAwait(true, 2500);
                var loginResponse = await userService.login({ username: `${this.user}`, password: `${this.pass}` });

                if (loginResponse.success) {
                    await this.loginUserAction(loginResponse.value); //Call store action
                }
                else {
                    this.error = loginResponse.error.replace(/['"]+/g, '');
                }
                 
                this.isLoading = false;

                if (this.isLoggedIn) { 
                    //Redirect user if user has successfully logged in
                    this.$router.push({ name: 'home' });
                }
            },

            validate() {

                //Trim username and email fields of white space
                this.user = this.user.trim();

                //Validation
                if (!this.validateUsername(this.user)) {
                    this.error = "Invalid username or password";
                    return false;
                }

                if (this.pass.trim() === "") {
                    this.error = "Password cannot be empty";
                    return false;
                }

                if (!this.validatePassword(this.pass)) {
                    this.error = "Invalid username or password";
                    return false;
                }

                return true;
            },

            validateUsername(username) {
                //Must be between 4 and 16 characters long
                //White spaces are not permitted
                //Must start with a letter
                //Only characters and numbers permitted
                return /^(?=.{4,16}$)(?!.* )[a-z]{1,}[a-z0-9]*$/i.test(username)
            },

            validatePassword(password) {
                return /^.{6,64}$/.test(password);
            },
        },

        /* COMPUTED PROPERTIES */
        computed: {

            ...mapGetters(['isLoggedIn']),

            loginDisplayClass() {
                if (this.isLoggedIn)
                    return "cts-form loading ok";
                else if (this.isLoading)
                    return "cts-form loading"
                else if (this.error !== null)
                    return "cts-form cts-error";
                else return "cts-form";
            },

            loginText() {
                if (this.isLoading)
                    return "Authenticating";
                else return "Log in";
            },

            disableLoginButton() {
                return this.isLoading || this.error !== null
            }
        },
    };
</script>