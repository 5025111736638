import { API } from '../shared/config';
import { HTTP_GET, HTTP_POST } from '../shared/constants';
import { serviceHelper } from '../shared/service-helper';

const login = async function(loginArgs) {
    try {
        const response = await serviceHelper.callApi(`${API}/login`, HTTP_POST, loginArgs);
        return response;
    } catch (error) {
        return null;
    }
}

const refreshToken = async function () {
    try {
        const response = await serviceHelper.callApi(`${API}/refreshToken`, HTTP_POST);
        return response;
    } catch (error) {
        return null;
    }
}

const register = async function(registerArgs) {
    try {
        const response = await serviceHelper.callApi(`${API}/register`, HTTP_POST, registerArgs);
        return response;
    } catch (error) {
        return null;
    }
}

const activate = async function (activationArgs) {
    try {
        const response = await serviceHelper.callApi(`${API}/activate`, HTTP_POST, activationArgs);
        return response;
    } catch (error) {
        return null;
    }
}

const unlock = async function (unlockArgs) {
    try {
        const response = await serviceHelper.callApi(`${API}/unlock`, HTTP_POST, unlockArgs);
        return response;
    } catch (error) {
        return null;
    }
}

const resetPassword = async function (resetPasswordArgs) {
    try {
        const response = await serviceHelper.callApi(`${API}/resetpassword`, HTTP_POST, resetPasswordArgs);
        return response;
    } catch (error) {
        return null;
    }
}

const changeForgottenPassword = async function (changeForgottenPasswordArgs) {
    try {
        const response = await serviceHelper.callApi(`${API}/changeforgottenpassword`, HTTP_POST, changeForgottenPasswordArgs);
        return response;
    } catch (error) {
        return null;
    }
}

const updatePassword = async function (vm, updatePasswordArgs) {
    try {

        if (!serviceHelper.validateToken(vm)) {
            return { success: false, value: null, error: "Session Expired" };
        }

        const response = await serviceHelper.callApi(`${API}/updatepassword`, HTTP_POST, updatePasswordArgs);
        return response;
    } catch (error) {
        return null;
    }
}

const searchForUsers = async function (vm, pageNumber, username, email, isLocked, isActive) {
    try {

        if (!serviceHelper.validateToken(vm)) {
            return { success: false, value: null, error: "Session Expired" };
        }
        var apiUrl = `${API}/SearchForUsers?pageNumber=${pageNumber}&username=${username}&email=${email}`;
        if (isLocked !== null)
            apiUrl += `&isLocked=${isLocked}`;

        if (isActive !== null)
            apiUrl += `&isActive=${isActive}`;

        const response = await serviceHelper.callApi(apiUrl, HTTP_GET);
        return response;
    } catch (error) {
        return null;
    }
}

export const userService = {
    login,
    refreshToken,
    register,
    activate,
    unlock,
    resetPassword,
    changeForgottenPassword,
    updatePassword,
    searchForUsers
};

