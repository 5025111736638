<template>
    <form class="cts-form-body" @submit.prevent="validateAccountCreation">
        <LegalDisclaimerForm ref="legalDisclaimerDialog" @acceptTerms="termsAccepted" />
        <div class="cts-wrapper" :style="secondaryBackground">
            <img src="../assets/CryptoTaxSpace_Logo.png" width="200" alt="logo" class="mb-4" />
            <div v-bind:class="accountDisplayClass">
                <p class="title">Account Registration</p>
                <input type="text" placeholder="Username" v-model="user" autofocus />
                <i class="fa"> <font-awesome-icon :icon="['fa', 'user']" /></i>
                <input type="email" placeholder="Email" v-model="email" autofocus />
                <i class="fa"> <font-awesome-icon :icon="['fa', 'envelope']" /></i>
                <input type="password" placeholder="Password" v-model="pass" />
                <i class="fa"> <font-awesome-icon :icon="['fa', 'key']" /></i>
                <input type="password" placeholder="Confirm Password" v-model="confirmPass" />
                <i class="fa"> <font-awesome-icon :icon="['fa', 'key']" /></i>
                <div style="text-align:center;">
                    <a href="#" @click="linkTo('login')">Login with existing account</a> <span style="color:black;"> | </span>
                    <a href="#" @click="linkTo('resetpassword')">Forgot your password?</a>
                </div>
                <button :disabled="disableRegisterButton" @click="validateAccountCreation()">
                    <i class="spinner"></i>
                    <div v-if="accountCreated">
                        <i class="fa"> <font-awesome-icon :icon="['fa', 'check-circle']" size="3x" class="mb-4" /></i>
                    </div>
                    <span style="font-size:medium;" v-if="!error">{{buttonText}}</span>
                    <span v-if="error" style="font-size:small;">{{error}}</span>

                    <div v-if="accountCreated">
                        <div class="mt-4" style="font-size:medium">Please check your inbox for an account activation email</div>
                        <br />
                        <div style="background-color:darkblue; color:white;" @click="linkTo('home')" class="btn btn-sm mr-5">
                            <i class="fa pr-2"> <font-awesome-icon :icon="['fa', 'home']" /></i>Home
                        </div>
                        <div style="background-color:purple; color:white;" @click="linkTo('login')" class="btn btn-sm">
                            <i class="fa pr-2"> <font-awesome-icon :icon="['fa', 'user']" /></i>Sign In
                        </div>
                    </div>
                </button>

            </div>
            <footer class="cts-footer"><a style="font-size:large;" target="blank" href="https://cryptotaxspace.com">CryptoTaxSpace © {{currentYear}}</a></footer>
        </div>
    </form>
</template>

<script>

    import { userService } from '../services/user-service';
    import { serviceHelper } from '../shared/service-helper';
    import LegalDisclaimerForm from '@/components/legal-disclaimer-form';

    export default {
        name: 'NewAccount',

        /* COMPONENT DATA  */
        data() {
            return {
                user: '',
                email: '',
                pass: '',
                currentYear: '',
                confirmPass: '',
                accountCreated: false,
                error: null,
                isLoading: false,
                acceptedTerms: false,
                secondaryBackground: {
                    backgroundImage: `url(${require('@/assets/dark-space-4.jpg')})`,
                    backgroundRepeat: 'repeat'
                },
            };
        },

        /* CHILD COMPONENTS  */
        components: {
            LegalDisclaimerForm
        },

        /* LIFE CYCLE METHODS  */
        async mounted() {
            this.currentYear = new Date().getFullYear();
        },

        /* WATCHERS */      
        watch: {

            //Watchers to clear error if the user makes any form change
            user: function (val, oldVal) {
                this.error = null;
            },

            pass: function (val, oldVal) {
                this.error = null;
            },

            confirmPass: function (val, oldVal) {
                this.error = null;
            },

            email: function (val, oldVal) {
                this.error = null;
            }

        },

        /* COMPONENT METHODS  */
        methods: {

            async linkTo(link) {
                this.$router.push({ name: link }).catch(err => { });
            },

            async validateAccountCreation() {

                this.error = null;

                //Do nothing if already loading
                if (this.isLoading) return;

                //Reject invalid input
                if (!this.validate()) return;

                if (!this.acceptedTerms) {
                    this.$refs.legalDisclaimerDialog.ShowModal();
                }
                else {
                    await this.createAccount();
                }
            },

            async termsAccepted() {

                this.acceptedTerms = true;
                await this.createAccount();
               
            },

            async createAccount() {

                this.isLoading = true;
             
                await serviceHelper.simulateAwait(true, 2500);

                var registerArgs = {
                    username: `${this.user}`,
                    password: `${this.pass}`,
                    email: `${this.email}`,
                };

                var response = await userService.register(registerArgs);
                this.isLoading = false;

                this.accountCreated = response.success;

                if (!this.accountCreated) {
                    this.error = response.error.replace(/['"]+/g, '');
                }

            },

            validate() {

                //Trim username and email fields of white space
                this.user = this.user.trim();
                this.email = this.email.trim();

                //Validation
                if (!this.validateUsername(this.user)){
                    this.error = "Invalid username";
                    return false;
                }

                if (!this.validateEmail(this.email)) {
                    this.error = "Invalid email address format";
                    return false;
                }

                if (this.pass !== this.confirmPass) {
                    this.error = "Passwords do not match";
                    return false;
                }

                if (this.pass.trim() === "") {
                    this.error = "Password cannot be empty";
                    return false;
                }

                if (!this.validatePassword(this.pass)) {
                    this.error = "Password length must be between 6 - 64 characters";
                    return false;
                }

                return true;            
            },

            validateUsername(username) {
                //Must be between 4 and 16 characters long
                //White spaces are not permitted
                //Must start with a letter
                //Only characters and numbers permitted
                return /^(?=.{4,16}$)(?!.* )[a-z]{1,}[a-z0-9]*$/i.test(username)
            },

            validateEmail(email) {
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
            },

            validatePassword(password) {
                return /^.{6,64}$/.test(password);
            }
        },

        /* COMPUTED PROPERTIES */
        computed: {

            accountDisplayClass() {
                if (this.accountCreated)
                    return "cts-form loading ok";
                else if (this.isLoading)
                    return "cts-form loading"
                else if (this.error !== null)
                    return "cts-form cts-error";
                else return "cts-form";
            },

            buttonText() {
                if (this.accountCreated)
                    return "Account Created";
                else if (this.isLoading)
                    return "Creating Account...";
                else return "Create New Account";
            },

            disableRegisterButton() {
                return this.isLoading || this.error !== null || this.accountCreated
            }
        },
    };
</script>